/* eslint-disable max-len */
import * as React from 'react';
import { navigate } from 'gatsby';

import { Box, Input, Stack, Typography, useTheme } from '@mui/material';

import Layout from '../components/Layout';
import SearchItem from '../components/Search';
import OutlookSwitcher from '../components/OutlookSwitcher';

import useSearchEntities from '../hooks/useSearchEntities';
import useTokenReplacement from '../hooks/useTokenReplacement';
import { homePage } from '../settings';

import homeBackground from '../images/home_background.png';
import logoClimadiag from '../images/logo_Blanc_Climadiag.png';
import francesadapte from '../images/france-s-adapte.png';

const IndexPage = () => {
  const searchEntities = useSearchEntities();
  const theme = useTheme();
  const replaceTokens = useTokenReplacement();

  return (
    <Layout
      maxWidth="unset"
      sx={{
        height: '100vh',
        padding: '50px',
        background:
          'radial-gradient(circle at calc(50% + 150px) 500px, rgba(46,170,225,1) 1%, rgba(46,170,225,1) 10%, rgba(16,79,144,1) 40%, rgba(16,79,144,1) 100%)',
        [theme.breakpoints.down('sm')]: {
          padding: '10px',
          background:
            'radial-gradient(circle at 50% 300px, rgba(46,170,225,1) 1%, rgba(46,170,225,1) 10%, rgba(16,79,144,1) 40%, rgba(16,79,144,1) 100%)',
        },
        color: 'white',
        width: '100%',
      }}
    >
      {/* <Typography variant="h2" gutterBottom>Les évolutions climatiques ciblées sur votre commune</Typography> */}

      <Stack
        sx={{
          flexDirection: 'row',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          sx={{
            width: 250,
            [theme.breakpoints.down('sm')]: {
              width: '80%',
            },
          }}
          component="img"
          src={logoClimadiag}
        />
        <Stack
          sx={{
            width: 500,
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              pt: 5,
            },
          }}
        >
          <OutlookSwitcher variant="home" />
          <SearchItem
            renderInput={params => (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ backgroundColor: 'white', borderRadius: 40 }}
                ref={params.InputProps.ref}
              >
                <Input
                  {...params.inputProps}
                  disableUnderline
                  sx={{
                    height: '70px',
                    [theme.breakpoints.down('sm')]: {
                      height: '50px',
                    },
                    width: 400,
                    marginLeft: 2,
                  }}
                  placeholder={homePage.searchText}
                />
              </Stack>
            )}
            // inputProps={{
            //   sx: { backgroundColor: 'white', border: 'none' },
            // }}
            options={searchEntities}
            getLabel={option => option?.label}
            getGroup={option => option?.type}
            onChange={(event, value) =>
              value?.identifiant && navigate(`/entity/${value?.identifiant}`)}
            searchLabel={homePage.searchText}
          />
        </Stack>
      </Stack>

      <Stack direction={{ default: 'column', sm: 'row' }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            maxWidth: '50%',
            textTransform: 'uppercase',
            fontWeight: 600,
            whiteSpace: 'pre-wrap',
            marginTop: 10,
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
              marginTop: 2,
            },
          }}
        >
          {replaceTokens(homePage.subtitle)}
        </Typography>
        <Box
          sx={{
            mt: 5,
            ml: 'auto',
            mr: { default: 'auto', sm: 4 },
            height: 80,
            display: 'block',
          }}
          component="img"
          src={francesadapte}
        />
      </Stack>
      <Box
        component="img"
        src={homeBackground}
        sx={{
          width: '100%',
          maxWidth: '1000px',
          display: 'block',
          [theme.breakpoints.down('sm')]: {
            mt: 5,
          },
          ml: 'auto',
          mr: 'auto',
        }}
      />
    </Layout>
  );
};

export default IndexPage;
